const cognitoConfigBeta = {
  UserPoolId: "ap-northeast-2_84mTqJXTZ",
  ClientId: "6chqv3n7uej09q1olo1b2cb44e",
  IdentityPoolId: "ap-northeast-2:3f064c10-1e07-4af7-8a4d-26fe815c35b7",
};
const s3ConfigBeta = {
  Bucket: "bookjoa-beta-user-data",
  ResourcesBucket: "bookjoa-share-resources",
};
const apiConfigBeta = {
  Endpoint: "https://q0ajfuwpn8.execute-api.ap-northeast-2.amazonaws.com",
};

const cognitoConfigProd = {
  UserPoolId: "ap-northeast-2_T3dqjgC73",
  ClientId: "32fn0nu2ejlvjucdb5mtmlkt15",
  IdentityPoolId: "ap-northeast-2:66040094-da26-4d3f-bce7-6b09085695eb",
};
const s3ConfigProd = {
  Bucket: "bookjoa-prod-user-data",
  ResourcesBucket: "bookjoa-share-resources",
};
const apiConfigProd = {
  Endpoint: "https://3ynubbqb2i.execute-api.ap-northeast-2.amazonaws.com",
};

let cognitoConfig = cognitoConfigProd;
let s3Config = s3ConfigProd;
let apiConfig = apiConfigProd;

let is_beta = window.location.href.includes("beta.") || window.location.href.includes("localhost");
if (is_beta) {
  cognitoConfig = cognitoConfigBeta;
  s3Config = s3ConfigBeta;
  apiConfig = apiConfigBeta;
}

export const configs = {
  cognito: cognitoConfig,
  s3: s3Config,
  api: apiConfig,
  region: "ap-northeast-2",
  is_beta: is_beta,
};
