/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment, useState, useEffect, useContext } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";
import Record from "pages/Presentation/sections/Record";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bookjoa_bg_ocean.jpg"; //bg-presentation.jpg

import { AuthContext } from "AuthContext";
import { getReadRecord } from "api/bookjoa";

import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";

import MKButton from "components/MKButton";

import AWS from "aws-sdk";
import { configs } from "configs";
import { createReadRecord } from "api/bookjoa";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { ImageList, ImageListItem } from "@mui/material";
import { CameraAlt, UploadFile } from "@mui/icons-material";

function Presentation() {
  const { user, token } = useContext(AuthContext);
  const [readRecords, setReadRecords] = useState([]);
  const [name, setName] = useState("");
  const [lastRecordKey, setLastRecordKey] = useState("a");
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const [scrollThreshold, setScrollThreshold] = useState(0.8);
  const [identityId, setIdentityId] = useState(null);

  async function fetchRecords() {
    let loadN = 2;
    let records = await getReadRecord(await token, loadN, lastRecordKey);
    if (!records || records.length == 0) {
      setHasMoreRecords(false);
      return;
    }
    if (records.length != loadN) {
      setHasMoreRecords(false);
    }
    setReadRecords((old) => [...old, ...records]);
    const newLast = records[records.length - 1].createTime;
    setLastRecordKey(newLast);
  }

  useEffect(() => {
    async function getUserData() {
      setName((await user).name);
      // get identityId
      const cognitoIdentity = new AWS.CognitoIdentity();
      const authenticator = `cognito-idp.${configs.region}.amazonaws.com/${configs.cognito.UserPoolId}`;
      const identityId = await cognitoIdentity
        .getId({
          IdentityPoolId: configs.cognito.IdentityPoolId,
          Logins: {
            [authenticator]: await token,
          },
        })
        .promise()
        .then((data) => {
          if (data.IdentityId) {
            return data.IdentityId;
          }
          return null;
        });
      setIdentityId(identityId);
    }
    getUserData();
    fetchRecords();
  }, []);

  const [uploadDialog, setUploadOpenDialog] = useState(false);
  const [uploadingState, setUploadingState] = useState(false);
  const [uploadingError, setUploadingError] = useState(false);
  const [uploadingHelperText, setUploadingHelperText] = useState("");
  const [uploadingTitle, setUploadingTitle] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState([]);

  const openUploadDialog = () => {
    setUploadOpenDialog(true);
  };

  const closeUploadDialog = () => {
    setUploadOpenDialog(false);
    setUploadingState(false);
    setUploadingError(false);
    setUploadingHelperText("");
    setUploadingTitle("");
    setUploadingFiles([]);
  };

  async function uploadBookRecord() {
    if (!uploadingTitle) {
      setUploadingError(true);
      setUploadingHelperText("title cannot be empty");
      return;
    }
    if (uploadingFiles.length == 0) {
      setUploadingError(true);
      setUploadingHelperText("need to specify image");
      return;
    }
    setUploadingState(true);

    let prefixPaths = [];
    for (const f of uploadingFiles) {
      const uploadRet = await f.uploadPromise;
      if (!uploadRet) {
        setUploadingState(false);
        setUploadingError(true);
        setUploadingHelperText("unable to upload file");
        return;
      }
      prefixPaths.push(f.prefixPath);
    }

    const ret = await createReadRecord(await token, uploadingTitle, prefixPaths);
    if (ret == null) {
      setUploadingState(false);
      setUploadingError(true);
      setUploadingHelperText("unable to create record");
      return;
    }
    closeUploadDialog();
    location.reload();
  }

  async function uploadBookRecordImage(e) {
    const target = e.target;
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const id = Date.parse(new Date());
        const suffix = file.name.split(".").pop();
        const targetName = id + "." + suffix;
        const prefixPath = identityId + "/" + targetName;
        var uploadPromise = new AWS.S3.ManagedUpload({
          params: {
            Bucket: configs.s3.Bucket,
            Key: prefixPath,
            Body: file,
          },
        }).promise();
        const newRecord = {
          id: id,
          file: file,
          prefixPath: prefixPath,
          uploadPromise: uploadPromise,
        };
        console.log("uploading", file.name, prefixPath);
        setUploadingFiles((old) => [...old, newRecord]);
      }

      if (uploadingError) {
        setUploadingError(false);
        setUploadingHelperText("");
      }
    }
  }

  return (
    <>
      <DefaultNavbar routes={routes} sticky uploadButton />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={7}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              안녕하세요 {name}님
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
              mb={3}
            >
              북좋아에 오신 것을 환영합니다
            </MKTypography>
            <MKButton
              color="default"
              sx={{ color: ({ palette: { dark } }) => dark.main }}
              onClick={openUploadDialog}
            >
              Upload
            </MKButton>
            <Dialog
              open={uploadDialog}
              onClose={closeUploadDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Upload Book Record"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please enter book title and also path of document photo
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="book-title"
                  label="Book title"
                  type="text"
                  fullWidth
                  variant="standard"
                  error={uploadingError}
                  helperText={uploadingHelperText}
                  onChange={(e) => {
                    if (uploadingError) {
                      setUploadingError(false);
                      setUploadingHelperText("");
                    }
                    setUploadingTitle(e.target.value);
                  }}
                />
                <label>
                  <CameraAlt fontSize="large" />
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={uploadBookRecordImage}
                    style={{ display: "none" }}
                  />
                </label>
                <ImageList cols={3}>
                  {uploadingFiles.map((f) => (
                    <ImageListItem key={f.id}>
                      <img src={`${URL.createObjectURL(f.file)}`} />
                    </ImageListItem>
                  ))}
                </ImageList>
              </DialogContent>
              <DialogActions>
                <Button onClick={uploadBookRecord} disabled={uploadingState}>
                  {uploadingState ? "Uploading" : "Upload"}
                </Button>
                <Button onClick={closeUploadDialog}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <InfiniteScroll
          dataLength={readRecords.length}
          next={fetchRecords}
          hasMore={hasMoreRecords}
          loader={<p>Loading...</p>}
          endMessage={<p />}
          scrollThreshold={scrollThreshold}
        >
          {readRecords.map((r) => (
            <Record key={r.createTime} record={r} />
          ))}
        </InfiniteScroll>
        {hasMoreRecords ? <CircularProgress /> : <></>}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
