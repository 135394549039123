import { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bookjoa_bg_graduation.jpg"; //bg-presentation.jpg

import { AuthContext } from "AuthContext";
import { getReadRecord } from "api/bookjoa";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { callFunction } from "api/bookjoa";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CircularProgress } from "@mui/material";

import { configs } from "configs";
import AWS from "aws-sdk";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListChildComponentProps from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";
import { PlayCircleFilled } from "@mui/icons-material";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { ImageList, ImageListItem } from "@mui/material";
import { CameraAlt, UploadFile } from "@mui/icons-material";
import useAsync from "react-async";

import InfiniteScroll from "react-infinite-scroll-component";
import TestRecord from "pages/VocabTesting/sections/TestRecord";

import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import SpeedIcon from "@mui/icons-material/Speed";

const VOCABS_ITEM_HEIGHT = 48;
const VOCABS_ITEM_PADDING_TOP = 8;
const VocabMenuProps = {
  PaperProps: {
    style: {
      maxHeight: VOCABS_ITEM_HEIGHT * 4.5 + VOCABS_ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function VocabTest() {
  const { user, token } = useContext(AuthContext);
  const [readRecords, setReadRecords] = useState([]);
  const [name, setName] = useState("");
  const [lastRecordKey, setLastRecordKey] = useState("a");
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const [scrollThreshold, setScrollThreshold] = useState(0.8);
  const [playbackRate, setPlaybackRate] = useState(80);

  const [testDialog, setTestDialog] = useState(false);
  const [newTest, setNewTest] = useState(null);

  const [voice, setVoice] = useState("g.en.0.1");
  const voiceName = {
    "g.en.0.0": "John",
    "g.en.0.1": "Jane",
    "g.en.2.0": "Emma",
    "g.en.2.1": "Wesley",
    "g.en.1.0": "Sri",
    "g.en.1.1": "Sunil",
  };
  const vocabLabels = [
    "en.k.0.1",
    "en.k.0.2",
    "en.k.0.3",
    "en.k.0.4",
    "en.k.0.5",
    "en.k.0.6",
    "en.k.0.7",
    "en.k.0.8",
  ];
  const [wordsN, setWordsN] = useState(5);
  const [fetchingVocab, setFetchingVocab] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploadingState, setUploadingState] = useState(false);
  const [uploadingError, setUploadingError] = useState(false);
  const [uploadingHelperText, setUploadingHelperText] = useState("");
  const playbackRateLabels = [
    {
      value: 100,
      label: "100%",
    },
    {
      value: 0,
      label: "10%",
    },
  ];

  const [identityId, setIdentityId] = useState(null);

  async function fetchRecords() {
    const limit = 2;
    const params = {
      limit: limit,
      next: lastRecordKey,
    };
    const records = await callFunction(await token, "get-stt-test-records", params);
    if (!records || records.length == 0) {
      setHasMoreRecords(false);
      return;
    }
    if (records.length != limit) {
      setHasMoreRecords(false);
    }
    setReadRecords((old) => [...old, ...records]);
    const newLast = records[records.length - 1].createTime;
    setLastRecordKey(newLast);
  }

  useEffect(() => {
    async function getUserData() {
      setName((await user).name);

      // get identityId
      const cognitoIdentity = new AWS.CognitoIdentity();
      const authenticator = `cognito-idp.${configs.region}.amazonaws.com/${configs.cognito.UserPoolId}`;
      const identityId = await cognitoIdentity
        .getId({
          IdentityPoolId: configs.cognito.IdentityPoolId,
          Logins: {
            [authenticator]: await token,
          },
        })
        .promise()
        .then((data) => {
          if (data.IdentityId) {
            return data.IdentityId;
          }
          return null;
        });
      setIdentityId(identityId);
    }
    getUserData();
    fetchRecords();
  }, []);

  async function openTestDialog() {
    setTestDialog(true);
  }

  async function startTest() {
    setFetchingVocab(true);
    const params = {
      voiceId: voice,
      numberOfWords: wordsN,
    };
    const ret = await callFunction(await token, "create-stt-test", params);
    const words = ret.word_paths;

    let s3 = new AWS.S3();
    let wordsText = [];
    let audioPromises = [];
    for (const w of words) {
      wordsText.push(w.word);
      audioPromises.push(
        s3
          .getObject({
            Bucket: configs.s3.ResourcesBucket,
            Key: w.path,
          })
          .promise()
      );
    }

    const newTest = wordsText.map(function (w, i) {
      return {
        pos: i + 1,
        word: w,
        audio: audioPromises[i],
      };
    });
    setFetchingVocab(false);
    setNewTest(newTest);
  }

  async function closeTestDialog() {
    setTestDialog(false);
    setFetchingVocab(false);
    setNewTest(null);
    setUploadingState(false);
    setUploadingError(false);
    setUploadingHelperText("");
    setUploadingFiles([]);
  }

  async function wordClick(e) {
    const id = parseInt(e.currentTarget.id);
    console.log("playing audio", id);
    let audioPath = await newTest[id].audio.then(
      function (data) {
        return URL.createObjectURL(new Blob([data.Body.buffer]));
      },
      function (err) {
        console.log("There was an error downloading your audio: ", err.message);
        return null;
      }
    );
    let audioObj = new Audio(audioPath);
    audioObj.playbackRate = playbackRate / 100.0;
    audioObj.play().catch((e) => {
      console.log("audio play e", e);
    });
  }

  function renderTestRow(props) {
    const { index, style } = props;
    const [loading, setLoading] = useState(true);

    newTest.at(index).audio.then(
      function (data) {
        setLoading(false);
      },
      function (err) {
        console.log("unable to load", index);
      }
    );

    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
        id={index}
        onClick={wordClick}
      >
        <ListItemButton>
          <ListItemIcon>
            {loading ? (
              <CircularProgress fontSize="small" />
            ) : (
              <PlayCircleFilled fontSize="large" />
            )}
          </ListItemIcon>
          <ListItemText primary={`${index + 1}번`} />
        </ListItemButton>
      </ListItem>
    );
  }

  function handleVoiceChange(event) {
    setVoice(event.target.value);
  }

  function handleWordsNChange(event) {
    setWordsN(event.target.value);
  }

  function handleVocabsChange(event) {
    const {
      target: { value },
    } = event;
  }

  function uploadTestRecordImage(e) {
    console.log("upload test record called");
    const target = e.target;
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const id = Date.parse(new Date());
        const suffix = file.name.split(".").pop();
        const targetName = id + "." + suffix;
        const prefixPath = identityId + "/" + targetName;
        var uploadPromise = new AWS.S3.ManagedUpload({
          params: {
            Bucket: configs.s3.Bucket,
            Key: prefixPath,
            Body: file,
          },
        }).promise();
        const newRecord = {
          id: id,
          file: file,
          prefixPath: prefixPath,
          uploadPromise: uploadPromise,
        };
        console.log("uploading", file.name, prefixPath);
        setUploadingFiles([newRecord]);
      }
    }
  }

  async function uploadTestRecord() {
    if (uploadingFiles.length == 0) {
      setUploadingError(true);
      setUploadingHelperText("need to specify image");
      return;
    } else {
      console.log("uploadingFiles", uploadingFiles);
    }
    setUploadingState(true);

    let prefixPaths = [];
    for (const f of uploadingFiles) {
      const uploadRet = await f.uploadPromise;
      if (!uploadRet) {
        setUploadingState(false);
        setUploadingError(true);
        setUploadingHelperText("unable to upload file");
        return;
      }
      prefixPaths.push(f.prefixPath);
    }

    const testingWords = newTest.map(function (t, i) {
      return t.word;
    });

    const params = {
      testParameters: {
        voiceId: voice,
        numberOfWords: wordsN,
      },
      words: testingWords,
      imagePaths: prefixPaths,
    };
    const ret = await callFunction(await token, "create-stt-test-record", params);
    if (ret == null) {
      setUploadingState(false);
      setUploadingError(true);
      setUploadingHelperText("unable to call create test record");
      return;
    }

    closeTestDialog();
    location.reload();
  }

  function handlePlaybackRateChange(event, newValue) {
    if (newValue < 10 || newValue > 100) {
      return;
    }
    setPlaybackRate(newValue);
  }

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              안녕하세요 {name}님
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              받아쓰기 페이지에 오신 것을 환영합니다
            </MKTypography>
            <MKButton
              color="default"
              sx={{ color: ({ palette: { dark } }) => dark.main }}
              onClick={openTestDialog}
            >
              시작하기
            </MKButton>

            <Dialog
              open={testDialog}
              onClose={closeTestDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"받아쓰기"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  받아쓰기 목소리, 문제집, 단어 개수를 선택후 시작하기 버튼을 눌러주시기 바랍니다
                </DialogContentText>
                <br />
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <FormControl fullWidth disabled={!!newTest}>
                      <Select value={voice} displayEmpty onChange={handleVoiceChange}>
                        {Object.entries(voiceName).map(([k, v]) => (
                          <MenuItem key={k} value={k}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth disabled={!!newTest}>
                      <Select value={wordsN} displayEmpty onChange={handleWordsNChange}>
                        <MenuItem value={5}>5 </MenuItem>
                        <MenuItem value={6}>6 </MenuItem>
                        <MenuItem value={7}>7 </MenuItem>
                        <MenuItem value={8}>8 </MenuItem>
                        <MenuItem value={9}>9 </MenuItem>
                        <MenuItem value={10}>10 </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <br />
                {fetchingVocab ? <CircularProgress /> : <></>}
                {newTest ? (
                  <Box>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Slider
                        aria-label="Volume"
                        value={playbackRate}
                        onChange={handlePlaybackRateChange}
                      />
                      <SpeedIcon fontSize="large" />
                      {playbackRate}%
                    </Stack>
                  </Box>
                ) : (
                  <></>
                )}
                {newTest ? (
                  <Box component="span">
                    <FixedSizeList
                      height={200}
                      itemSize={40}
                      itemCount={newTest.length}
                      overscanCount={1}
                    >
                      {renderTestRow}
                    </FixedSizeList>
                    <br />
                    <label>
                      <CameraAlt fontSize="large" />
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={uploadTestRecordImage}
                        style={{ display: "none" }}
                      />
                    </label>
                    <ImageList cols={3}>
                      {uploadingFiles.map((f) => (
                        <ImageListItem key={f.id}>
                          <img src={`${URL.createObjectURL(f.file)}`} />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                ) : (
                  <></>
                )}
              </DialogContent>
              <DialogActions>
                {newTest ? (
                  <Button onClick={uploadTestRecord} disabled={uploadingState}>
                    {uploadingState ? "제출중" : "제출"}
                  </Button>
                ) : (
                  <Button onClick={startTest} disabled={fetchingVocab}>
                    시작하기
                  </Button>
                )}
                <Button onClick={closeTestDialog}>취소</Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <InfiniteScroll
              dataLength={readRecords.length}
              next={fetchRecords}
              hasMore={hasMoreRecords}
              loader={<p>Loading...</p>}
              endMessage={<p />}
              scrollThreshold={scrollThreshold}
            >
              {readRecords.map((r) => (
                <TestRecord key={r.createTime} record={r} />
              ))}
            </InfiniteScroll>
          </Grid>
        </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default VocabTest;
