import axios from "axios";
import { configs } from "configs";

export async function callFunction(authToken, apiName, params) {
  const headers = {
    Authorization: authToken,
  };
  const ep = configs.api.Endpoint + "/" + apiName;
  return axios
    .post(ep, params, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("unable to invoke bookjoa api", apiName, params, err);
      return null;
    });
}

export async function getReadRecord(authToken, limit, next) {
  const headers = {
    Authorization: authToken,
  };
  const ep = configs.api.Endpoint + "/get-read-record";
  const params = {
    limit: limit,
    next: next,
  };
  return axios
    .post(ep, params, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("unable to invoke bookjoa api", err);
      return null;
    });
}

export async function createReadRecord(authToken, bookTitle, imagePaths) {
  const headers = {
    Authorization: authToken,
  };
  const ep = configs.api.Endpoint + "/create-read-record";
  const params = {
    bookTitle: bookTitle,
    imagePaths: imagePaths,
  };
  return axios
    .post(ep, params, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("unable to invoke bookjoa api", err);
      return null;
    });
}
