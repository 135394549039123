import { Fragment, useState, useEffect, useContext } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Paper, Card } from "@mui/material";

import { AuthContext } from "AuthContext";

import { configs } from "configs";
import AWS from "aws-sdk";

import { CircularProgress } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FaceIcon from "@mui/icons-material/Face";

import { callFunction } from "api/bookjoa";

function TestRecord({ record }) {
  const { user, token } = useContext(AuthContext);
  const [paths, setPaths] = useState([]);
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);
  const [recordLocal, setRecordLocal] = useState(record);
  const [audioCache, setAudioCache] = useState({});

  useEffect(() => {
    async function loadImages() {
      let imagePromises = [];
      let s3 = new AWS.S3();
      for (const p of record.imagePaths) {
        imagePromises.push(
          s3
            .getObject({
              Bucket: configs.s3.Bucket,
              Key: p,
            })
            .promise()
        );
      }
      let imagePaths = [];
      for (const p of imagePromises) {
        await p.then(
          function (data) {
            imagePaths.push(URL.createObjectURL(new Blob([data.Body.buffer])));
          },
          function (err) {
            console.log("There was an error downloading your image: ", err.message);
          }
        );
      }
      setPaths(imagePaths);

      if (record.found.length == 0) {
        var testRecordRetryLimit = 2;
        var intervalId = setInterval(async function () {
          const params = {
            key: record.createTime,
          };
          const newRecords = await callFunction(await token, "get-stt-test-records", params);
          if (newRecords) {
            const newRecord = newRecords[0];
            if (newRecord.found.length != 0) {
              clearInterval(intervalId);
              setRecordLocal(newRecord);
              return;
            }
          }
          console.log(record.createTime, "retrying at", Date());
          testRecordRetryLimit--;
          if (testRecordRetryLimit <= 0) {
            console.log(record.createTime, "giving up at", Date());
            clearInterval(intervalId);
          }
        }, 5000);
      }
    }

    loadImages();
  }, []);

  function getRecordDate() {
    return new Date(Date.parse(record.createTime + "Z")).toLocaleString();
  }

  async function wordClick(e) {
    const id = parseInt(e.currentTarget.id);
    const voiceId = record.testParameters.voiceId;
    const word = record.wordsArr[id];
    const audioPath = "tts/" + voiceId + "." + word + ".mp3";
    let audioLocalPath = audioCache[audioPath];
    if (!audioLocalPath) {
      audioLocalPath = await new AWS.S3()
        .getObject({
          Bucket: configs.s3.ResourcesBucket,
          Key: audioPath,
        })
        .promise()
        .then(
          function (data) {
            return URL.createObjectURL(new Blob([data.Body.buffer]));
          },
          function (err) {
            console.log("There was an error downloading your audio: ", err.message);
            return null;
          }
        );
      setAudioCache((prevState) => ({
        ...prevState,
        [audioPath]: audioLocalPath,
      }));
    }
    let audioObj = new Audio(audioLocalPath);
    audioObj.play().catch((e) => {
      console.log("audio play e", e);
    });
  }

  return (
    <Card
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      {paths.length > 0 ? (
        <img src={paths[0]} onLoad={() => setFirstImgLoaded(true)} style={{ display: "none" }} />
      ) : (
        <></>
      )}
      {firstImgLoaded ? (
        <Carousel autoPlay={false}>
          {paths.map((path) => (
            <img src={path} key={path} width="100%" height="auto" />
          ))}
        </Carousel>
      ) : (
        <CircularProgress />
      )}
      <MKBox pt={2} pb={3}>
        {recordLocal.found.length != 0 ? (
          <MKTypography variant="h5" gutterBottom>
            +{recordLocal.found.reduce((a, b) => a + b, 0)} 콩
          </MKTypography>
        ) : (
          <></>
        )}
        <MKTypography variant="body2" component="p" color="text" mb={3}>
          {getRecordDate()}
          {window.location.href.includes("localhost") ? " " + recordLocal.createTime : ""}
        </MKTypography>
        {recordLocal.found.map((value, i) =>
          value ? (
            <Chip
              icon={<FaceIcon />}
              key={i}
              label={recordLocal.wordsArr[i]}
              variant="outlined"
              id={i}
              onClick={wordClick}
              on="true"
            />
          ) : (
            <Chip key={i} label={recordLocal.wordsArr[i]} id={i} onClick={wordClick} />
          )
        )}
        {recordLocal.found.length == 0 ? <CircularProgress /> : <></>}
      </MKBox>
    </Card>
  );
}

export default TestRecord;
