import { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bookjoa_bg_movie_theater.jpg"; //bg-presentation.jpg

import { AuthContext } from "AuthContext";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { callFunction } from "api/bookjoa";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CircularProgress } from "@mui/material";

import { configs } from "configs";
import AWS from "aws-sdk";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListChildComponentProps from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";
import { PlayCircleFilled } from "@mui/icons-material";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { ImageList, ImageListItem } from "@mui/material";
import { CameraAlt, UploadFile } from "@mui/icons-material";
import useAsync from "react-async";

import InfiniteScroll from "react-infinite-scroll-component";
import TestRecord from "pages/VocabTesting/sections/TestRecord";

import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import SpeedIcon from "@mui/icons-material/Speed";
import VideoLesson from "./sections/VideoLesson";

const VOCABS_ITEM_HEIGHT = 48;
const VOCABS_ITEM_PADDING_TOP = 8;
const VocabMenuProps = {
  PaperProps: {
    style: {
      maxHeight: VOCABS_ITEM_HEIGHT * 4.5 + VOCABS_ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function VideoTraining() {
  const { user, token } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [lastRecordKey, setLastRecordKey] = useState("a");

  const videos = [
    "BTS - Permission to Dance",
    "LE SSERAFIM - Eve Psyche & the Bluebeard’s wife (English Ver)",
    "SPIDER-MAN HOMECOMING Official Trailer",
    "Avengers Endgame Official Trailer",
    "Blackpink Jennie Met Gala 2023 Vogue",
    "Blackpink Rosés Bag Vogue",
    "Steve Jobs 2005 Stanford Commencement Address",
    "Steve Jobs unveils the iPhone",
  ];

  const [identityId, setIdentityId] = useState(null);

  useEffect(() => {
    async function getUserData() {
      setName((await user).name);

      // get identityId
      const cognitoIdentity = new AWS.CognitoIdentity();
      const authenticator = `cognito-idp.${configs.region}.amazonaws.com/${configs.cognito.UserPoolId}`;
      const identityId = await cognitoIdentity
        .getId({
          IdentityPoolId: configs.cognito.IdentityPoolId,
          Logins: {
            [authenticator]: await token,
          },
        })
        .promise()
        .then((data) => {
          if (data.IdentityId) {
            return data.IdentityId;
          }
          return null;
        });
      setIdentityId(identityId);
    }
    getUserData();
  }, []);

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              안녕하세요 {name}님
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Welcome to video training center
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            {videos.map((v) => (
              <VideoLesson key={v} lesson={v} />
            ))}
          </Grid>
        </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default VideoTraining;
