import { Fragment, useState, useEffect, useContext } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Paper, Card } from "@mui/material";

import { AuthContext } from "AuthContext";

import { configs } from "configs";
import AWS from "aws-sdk";

import { CircularProgress } from "@mui/material";
import Carousel from "react-material-ui-carousel";

function Record({ record }) {
  const [paths, setPaths] = useState([]);
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);

  useEffect(() => {
    async function loadImages() {
      let imagePromises = [];
      let s3 = new AWS.S3();
      for (const p of record.imagePaths) {
        imagePromises.push(
          s3
            .getObject({
              Bucket: configs.s3.Bucket,
              Key: p,
            })
            .promise()
        );
      }
      let imagePaths = [];
      for (const p of imagePromises) {
        await p.then(
          function (data) {
            imagePaths.push(URL.createObjectURL(new Blob([data.Body.buffer])));
          },
          function (err) {
            console.log("There was an error downloading your image: ", err.message);
          }
        );
      }
      setPaths(imagePaths);
    }

    loadImages();
  }, []);

  function getRecordDate() {
    return new Date(Date.parse(record.createTime + "Z")).toLocaleString();
  }

  return (
    <Card
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      {paths.length > 0 ? (
        <img src={paths[0]} onLoad={() => setFirstImgLoaded(true)} style={{ display: "none" }} />
      ) : (
        <></>
      )}
      {firstImgLoaded ? (
        <Carousel autoPlay={false}>
          {paths.map((path) => (
            <img src={path} key={path} width="100%" height="auto" />
          ))}
        </Carousel>
      ) : (
        <CircularProgress />
      )}
      <MKBox pt={2} pb={3}>
        <MKTypography variant="h5" gutterBottom>
          {record.bookTitle}
        </MKTypography>
        <MKTypography variant="body2" component="p" color="text" mb={3}>
          {getRecordDate()}
        </MKTypography>
      </MKBox>
    </Card>
  );
}

export default Record;
