import { CognitoUserPool, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { configs } from "./configs";

var AWS = require("aws-sdk");

const userPool = new CognitoUserPool({
  UserPoolId: configs.cognito.UserPoolId,
  ClientId: configs.cognito.ClientId,
});

export function signUp(username, email, password) {
  // Sign up implementation
}

export function confirmSignUp(username, code) {
  // Confirm sign up implementation
}

export function signIn(username, password) {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
}

export function forgotPassword(username) {
  // Forgot password implementation
}

export function confirmPassword(username, code, newPassword) {
  // Confirm password implementation
}

export function signOut() {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
}

export async function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      reject(new Error("No user found"));
      return;
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
          return;
        }
        const userData = attributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value;
          return acc;
        }, {});
        resolve({ ...userData, username: cognitoUser.username });
      });
    });
  });
}

export function getToken() {
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"));
      return;
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export function setAwsCredentials(userToken) {
  const authenticator = `cognito-idp.${configs.region}.amazonaws.com/${configs.cognito.UserPoolId}`;
  AWS.config.update({ region: configs.region });
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: configs.cognito.IdentityPoolId,
    Logins: {
      [authenticator]: userToken,
    },
  });
  return AWS.config.credentials;
}
