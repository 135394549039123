import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "AuthContext";
import { useContext } from "react";

export default function SignOut() {
  const { signOut } = useContext(AuthContext);
  useEffect(() => {
    signOut();
  });
  return <CircularProgress />;
}
