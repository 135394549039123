// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Images
import coinbase from "assets/images/logos/gray-logos/logo-coinbase.svg";
import nasa from "assets/images/logos/gray-logos/logo-nasa.svg";
import netflix from "assets/images/logos/gray-logos/logo-netflix.svg";
import pinterest from "assets/images/logos/gray-logos/logo-pinterest.svg";
import spotify from "assets/images/logos/gray-logos/logo-spotify.svg";
import vodafone from "assets/images/logos/gray-logos/logo-vodafone.svg";

function Intro() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={1}
              title="진단"
              description="교과서도서지수 테스트를 통한 학생의 독서능력 진단"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={2}
              title="읽기"
              description="독서능력에 딱 맞는 맞춤형 레벨 추천도서 읽기"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={3}
              title="노트"
              description="독서 후 독서진단과 독서노트 작성"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Intro;
