import { useState, useEffect, useContext, useRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Paper, Card } from "@mui/material";

import { AuthContext } from "AuthContext";

import { configs } from "configs";
import AWS from "aws-sdk";

import { CircularProgress } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FaceIcon from "@mui/icons-material/Face";

import { callFunction } from "api/bookjoa";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListChildComponentProps from "@mui/material/ListItemText";
import { PlayCircleFilled } from "@mui/icons-material";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import SpeedIcon from "@mui/icons-material/Speed";

import { FixedSizeList, VariableSizeList } from "react-window";

import { Navigate } from "react-router-dom";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function VideoLesson({ lesson }) {
  const [transcript, setTranscript] = useState(null);

  const [playbackRate, setPlaybackRate] = useState(100);
  const [coverSrc, setCoverSrc] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    async function loadCover() {
      let coverObj = await new AWS.S3()
        .getObject({
          Bucket: configs.s3.ResourcesBucket,
          Key: "vid/" + lesson + "/cover.jpg",
        })
        .promise()
        .then(
          function (data) {
            return data;
          },
          function (err) {
            console.log("There was an error downloading transcript: ", err.message);
            return null;
          }
        );

      let coverSrcUrl = URL.createObjectURL(new Blob([coverObj.Body.buffer]));
      setCoverSrc(coverSrcUrl);
    }
    async function loadTranscript() {
      let transcriptObj = await new AWS.S3()
        .getObject({
          Bucket: configs.s3.ResourcesBucket,
          Key: "vid/" + lesson + "/transcribe.json",
        })
        .promise()
        .then(
          function (data) {
            return data;
          },
          function (err) {
            console.log("There was an error downloading transcript: ", err.message);
            return null;
          }
        );
      const transcript = JSON.parse(transcriptObj.Body.toString("utf-8")).items;
      setTranscript(transcript);
    }
    loadCover();
    loadTranscript();
  }, []);

  const setPlaybackRateRef = () => {
    videoRef.current.playbackRate = playbackRate / 100.0;
  };

  function handlePlaybackRateChange(event, newValue) {
    if (newValue < 10 || newValue > 100) {
      return;
    }
    setPlaybackRate(newValue);
  }

  function handleRepeatCheck(e) {
    setRepeat(e.target.checked);
  }

  async function updateVideo(s) {
    let video = null;
    if (s.video == null) {
      video = await new AWS.S3()
        .getObject({
          Bucket: configs.s3.ResourcesBucket,
          Key: "vid/" + lesson + "/" + s.start + ".mp4",
        })
        .promise()
        .then(
          function (data) {
            return URL.createObjectURL(new Blob([data.Body.buffer]));
          },
          function (err) {
            console.log("There was an error downloading transcript: ", err.message);
            return null;
          }
        );
      s.video = video;
    } else {
      video = s.video;
    }
    if (videoSrc == video) {
      videoRef.current.play();
    } else {
      setVideoSrc(video);
    }
  }

  async function lineClick(e) {
    const id = parseInt(e.currentTarget.id);
    updateVideo(transcript[id]);
    setAutoPlay(true);
  }

  function renderLine(props) {
    const { index, style } = props;
    const [loading, setLoading] = useState(false);

    const sentence = transcript[index].sentence;
    const l = sentence.length;
    let fontSize = 20;
    if (l > 20) {
      fontSize = 15;
      if (l > 40) {
        fontSize = 10;
      }
    }
    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
        id={index}
        onClick={lineClick}
      >
        <ListItemButton>
          <ListItemText secondary={sentence} />
        </ListItemButton>
      </ListItem>
    );
  }

  const getItemSize = function (index) {
    const s = transcript[index];
    const l = s.sentence.length;
    let ret = 40;
    if (l > 20) {
      ret = 40 + (l / 20) * 10;
    }
    return ret;
  };

  return (
    <Card
      sx={{
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MKTypography variant="h5" gutterBottom>
        {lesson}
      </MKTypography>
      <MKBox pt={2} pb={3}>
        {videoSrc ? (
          <video
            controls
            ref={videoRef}
            width="100%"
            onCanPlay={() => setPlaybackRateRef()}
            autoPlay={autoPlay}
            src={videoSrc}
            loop={repeat}
          />
        ) : (
          <img src={coverSrc} width="100%" />
        )}
        <Box>
          <Stack spacing={2} direction="row" alignItems="center">
            <Slider aria-label="Volume" value={playbackRate} onChange={handlePlaybackRateChange} />
            <SpeedIcon fontSize="large" />
            {playbackRate}%
          </Stack>
          <FormGroup>
            <FormControlLabel control={<Checkbox onChange={handleRepeatCheck} />} label="Repeat" />
          </FormGroup>
        </Box>
        {transcript && transcript.length > 0 ? (
          <VariableSizeList
            height={300}
            width="100%"
            itemCount={transcript.length}
            itemSize={getItemSize}
            overscanCount={1}
          >
            {renderLine}
          </VariableSizeList>
        ) : (
          <></>
        )}
      </MKBox>
    </Card>
  );
}

export default VideoLesson;
